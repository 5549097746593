import { VSelectItem } from "@/core/interfaces/v-select.interface";
import { Language } from "@/core/references/languages/language.interface";
import { AxiosInstance } from "axios";

export class LanguagesReference {
  #list: Language[] = [];

  constructor(private http: AxiosInstance) {}

  async getList(): Promise<Language[]> {
    if (this.#list.length > 0) {
      return this.#list;
    }

    try {
      const { data } = await this.http.get("/v1/references/languages");
      this.#list = data?.data || [];

      return this.#list;
    } catch (e) {
      console.log(e.response);
    }

    return [];
  }

  async getSelectItems(): Promise<VSelectItem[]> {
    if (!this.#list.length) {
      await this.getList();
    }

    return this.#list.map((item) => ({
      text: item.name,
      value: item.code,
    }));
  }
}
