import "material-design-icons-iconfont/dist/material-design-icons.css";
// import "@/assets/scss/theme/overrides.sass";

import Vue from "vue";
import i18n from "@/i18n";
import store from "@/store";
import Vuetify from "vuetify/lib/framework";

import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";

const theme = {
  primary: "#00CAE3",
  secondary: "#9C27b0",
  accent: "#9C27b0",
  info: "#00CAE3",
};

const vuetify = new Vuetify({
  icons: {
    iconfont: "md", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  lang: {
    current: i18n.locale,
    t: (key, ...params) => i18n.t(key, params) as string,
  },
  theme: {
    themes: {
      // dark: theme,
      light: theme,
    },
  },
});

Vue.use(Vuetify);

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
    store,
  },
  actions: {
    false: "Отменить",
    true: {
      text: "Подтвердить",
      color: "primary",
    },
  },
});

export default vuetify;
