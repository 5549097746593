import { VSelectItem } from "@/core/interfaces/v-select.interface";
import { University } from "@/core/references/universities/university.interface";
import { AxiosInstance } from "axios";

export class UniversitiesReference {
  #list: University[] = [];

  constructor(private http: AxiosInstance) {}

  async getList(name = ""): Promise<University[]> {
    try {
      const { data } = await this.http.get(`/v1/references/universities`, {
        params: { name },
      });
      this.#list = data?.data || [];

      return this.#list;
    } catch (e) {
      console.log(e.response);
    }

    return [];
  }

  async getByCode(id: string): Promise<University | null> {
    try {
      const { data } = await this.http.get(`/v1/references/universities/${id}`);
      return data?.data;
    } catch (e) {
      console.log(e.response);
    }

    return null;
  }

  getSelectItems(): VSelectItem[] {
    return this.#list.map((item) => ({
      text: item.name,
      value: item.id,
    }));
  }
}
