import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import { authRouter } from "@/modules/auth/auth.router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...authRouter,
  {
    path: "/",
    component: () => import("@/views/layouts/default.layout.vue"),
    meta: {
      authOnly: true,
    },
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../views/pages/home.page.vue" /* webpackChunkName: "home" */),
      },
      {
        path: "cabinet",
        name: "cabinet",
        component: () => import("@/modules/cabinet/cabinet.page.vue" /* webpackChunkName: "cabinet" */),
        meta: {
          title: "Личный кабинет",
        },
      },
      {
        path: "patients",
        name: "patients",
        component: () => import("@/modules/patients/patients.page.vue" /* webpackChunkName: "patients" */),
        meta: {
          title: "Пациенты",
        },
      },
      {
        path: "schedule",
        name: "schedule",
        component: () => import("@/modules/schedule/schedule.page.vue" /* webpackChunkName: "schedule" */),
        meta: {
          title: "Расписание",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function isLoggedIn() {
  return !!store.state["auth"].accessToken;
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: "/auth/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      next({
        path: "/",
        query: {},
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
