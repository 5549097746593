export const menu = [
  {
    title: "Личный кабинет",
    to: "/cabinet",
    icon: "mdi-home",
    group: "",
    subItems: [],
  },
  {
    title: "Пациенты",
    to: "/patients",
    icon: "mdi-home",
    group: "",
    subItems: [],
  },
  {
    title: "Расписание",
    to: "/schedule",
    icon: "mdi-home",
    group: "",
    subItems: [],
  },
  // Для примера формирования под меню
  /*{
		title: 'Пример под-меню',
		to: '/page',
		icon: 'mdi-history',
		group: 'pages',
		subItems: [
			{
				title: 'Под меню 1',
				to: '/',
				icon: '',
			},
		],
	}*/
];
