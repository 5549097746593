import "./assets/scss/style.scss";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import { CityReference } from "@/core/references/city";
import { ClinicsReference } from "@/core/references/clinics";
import { CountryReference } from "@/core/references/country";
import { DoctorCategoriesReference } from "@/core/references/doctor-categories";
import { DoctorEducationsReference } from "@/core/references/doctor-educations";
import { LanguagesReference } from "@/core/references/languages/languages.reference";
import { PrivacyParametersReference } from "@/core/references/privacy-parameters";
import { ScientificDegreesReference } from "@/core/references/scientific-degrees";
import { SpecializationsReference } from "@/core/references/specializations";
import { StreetReference } from "@/core/references/street";
import { UniversitiesReference } from "@/core/references/universities";
import { Registry } from "@/core/Registry";
import { ScheduleRepository } from "@/modules/schedule/schedule.repository";
import { ApiHttp } from "@/plugins/http.plugin";

import "./plugins/http.plugin";
import "./plugins/date.plugin";
import vuetify from "./plugins/vuetify";
import { SexReference } from "@/core/references/sex";

// Подключаются ниже всех что бы иметь приоритет

Vue.config.productionTip = false;

// Service Registry
const serviceRegistry = new Registry();
serviceRegistry.set(CityReference, new CityReference(ApiHttp));
serviceRegistry.set(ClinicsReference, new ClinicsReference(ApiHttp));
serviceRegistry.set(CountryReference, new CountryReference(ApiHttp));
serviceRegistry.set(DoctorCategoriesReference, new DoctorCategoriesReference(ApiHttp));
serviceRegistry.set(DoctorEducationsReference, new DoctorEducationsReference(ApiHttp));
serviceRegistry.set(LanguagesReference, new LanguagesReference(ApiHttp));
serviceRegistry.set(PrivacyParametersReference, new PrivacyParametersReference());
serviceRegistry.set(ScientificDegreesReference, new ScientificDegreesReference(ApiHttp));
serviceRegistry.set(SexReference, new SexReference(ApiHttp));
serviceRegistry.set(SpecializationsReference, new SpecializationsReference(ApiHttp));
serviceRegistry.set(StreetReference, new StreetReference(ApiHttp));
serviceRegistry.set(UniversitiesReference, new UniversitiesReference(ApiHttp));
serviceRegistry.set(ScheduleRepository, new ScheduleRepository(ApiHttp));

Vue.use({
  install(Vue) {
    Vue.prototype.$registry = serviceRegistry;
  },
});

(async () => {
  await store.dispatch("auth/loadToken");

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
    async created() {
      await store.dispatch("auth/loadProfile");
    },
  }).$mount("#app");
})();
