import * as dayjs from "dayjs";

import advancedFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import relativeTime from "dayjs/plugin/relativeTime";
import Vue from "vue";

dayjs.locale("ru");
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);

Vue.use({
  install(Vue) {
    Vue.prototype.$date = dayjs;
  },
});
