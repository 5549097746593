import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

import ruLang from "@/locales/ru.json";
import enLang from "@/locales/en.json";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const messages: LocaleMessages = {
    en: enLang,
    ru: ruLang,
  };

  return messages;
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "ru",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});

export default i18n;
