export type ConstructorOf<T> = new (...args: any[]) => T;

// Простая реализация Сервис контейнера, нужно выпилить ServiceRegistry.createFreshSrvRegistry()
export class Registry {
  private servicesMap = new Map<ConstructorOf<any>, any>();

  set<T>(ctor: ConstructorOf<T>, instance: unknown): Registry {
    this.servicesMap.set(ctor, instance);
    return this;
  }

  get<T>(ctor: ConstructorOf<T>): T {
    if (!this.servicesMap.has(ctor)) {
      throw new Error(`${ctor} не загружен в контейнер`);
    }

    return this.servicesMap.get(ctor);
  }
}
