import { API_URL } from "@/configs";
import router from "@/router";
import store from "@/store/index";
import axios from "axios";
import Vue from "vue";

const ApiHttp = axios.create();
ApiHttp.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

ApiHttp.interceptors.request.use(
  (config) => {
    config.url = config.url?.startsWith("/") ? `${API_URL}${config.url}` : `${API_URL}/${config.url}`;

    if (store.state["auth"].accessToken) {
      config.headers.Authorization = store.state["auth"].accessToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Add a response interceptor
ApiHttp.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  // remember to make this async as the store action will
  // need to be awaited
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // const originalRequest = error.config;

    if (error.response.status === 401 && router.currentRoute.name !== "login") {
      await router.push("/auth/login");
    }
    /*else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // await execution of the store async action before
      return axios(originalRequest);
    }*/

    return Promise.reject(error);
  },
);

Vue.use({
  install(Vue) {
    Vue.prototype.$api = ApiHttp;
    Vue.prototype.$http = axios;
  },
});

export { ApiHttp };
