import Vue from "vue";
import Vuex from "vuex";
import authModule from "@/modules/auth/auth.store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: null,
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage: "",
  },

  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
  },

  actions: {},

  modules: {
    auth: authModule,
  },
});
