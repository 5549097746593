import { VSelectItem } from "@/core/interfaces/v-select.interface";
import { PrivacyParameter } from "@/core/references/privacy-parameters/privacy-parameters.interface";

/**
 * Справочник приватности полей
 */
export class PrivacyParametersReference {
  #list: PrivacyParameter[] = [
    { id: 0, title: "Не показывать" },
    { id: 1, title: "Показывать" },
    { id: 2, title: "Показывать друзьям" },
  ];

  async getList(): Promise<PrivacyParameter[]> {
    return this.#list;
  }

  async getSelectItems(): Promise<VSelectItem[]> {
    return this.#list.map((item) => ({
      text: item.title,
      value: item.id,
    }));
  }
}
