import { VSelectItem } from "@/core/interfaces/v-select.interface";
import { City } from "@/core/references/city/city.interface";
import { AxiosInstance } from "axios";

export class CityReference {
  #list: City[] = [];

  constructor(private http: AxiosInstance) {}

  async getList(countryCode: string, name = ""): Promise<City[]> {
    try {
      const { data } = await this.http.get(`/v1/references/countries/${countryCode}/cities`, {
        params: { name },
      });
      this.#list = data?.data || [];

      return this.#list;
    } catch (e) {
      console.log(e.response);
    }

    return [];
  }

  async getByCode(countryCode: string, code: string): Promise<City | null> {
    try {
      const { data } = await this.http.get(`/v1/references/countries/${countryCode}/cities/${code}`);
      return data?.data;
    } catch (e) {
      console.log(e.response);
    }

    return null;
  }

  getSelectItems(): VSelectItem[] {
    return this.#list.map((item) => ({
      text: item.name,
      value: item.code,
    }));
  }
}
