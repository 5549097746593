import { VSelectItem } from "@/core/interfaces/v-select.interface";
import { ScientificDegree } from "@/core/references/scientific-degrees/scientific-degree.interface";
import { AxiosInstance } from "axios";

export class ScientificDegreesReference {
  #list: ScientificDegree[] = [];

  constructor(private http: AxiosInstance) {}

  async getList(): Promise<ScientificDegree[]> {
    if (this.#list.length > 0) {
      return this.#list;
    }

    try {
      const { data } = await this.http.get("/v1/references/scientific-degrees");
      this.#list = data?.data || [];

      return this.#list;
    } catch (e) {
      console.log(e.response);
    }

    return [];
  }

  async getSelectItems(): Promise<VSelectItem[]> {
    if (!this.#list.length) {
      await this.getList();
    }

    return this.#list.map((item) => ({
      text: item.name,
      value: item.id,
    }));
  }
}
