import { VSelectItem } from "@/core/interfaces/v-select.interface";
import { Street } from "@/core/references/street/street.interface";
import { AxiosInstance } from "axios";

export class StreetReference {
  #list: Street[] = [];

  constructor(private http: AxiosInstance) {}

  async getList(countryCode: string, cityCode: string, name: string): Promise<Street[]> {
    try {
      const { data } = await this.http.get(`/v1/references/countries/${countryCode}/cities/${cityCode}/streets`, {
        params: {
          name,
        },
      });
      this.#list = data?.data || [];

      return this.#list;
    } catch (e) {
      console.log(e.response);
    }

    return [];
  }

  async getByCode(country: string, cityCode: string, streetCode: string): Promise<Street | null> {
    try {
      const { data } = await this.http.get(`/v1/references/countries/${country}/cities/${cityCode}/streets/${streetCode}`);
      return data?.data;
    } catch (e) {
      console.log(e.response);
    }

    return null;
  }

  getSelectItems(): VSelectItem[] {
    return this.#list.map((item) => ({
      text: item.name,
      value: item.code,
    }));
  }
}
