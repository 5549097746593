import { AxiosInstance } from "axios";

export class ScheduleRepository {
  constructor(private http: AxiosInstance) {}

  async getCalendar(params: any = {}): Promise<any | null> {
    try {
      const response = await this.http.get("v1/mis/calendar-schedule", {
        params: {
          week: params.week,
          week_type: params.weekType,
        },
      });
      return response.data;
    } catch (e) {
      console.log("ScheduleRepository.getCalendar", e.response);
    }

    return null;
  }
  async getCalendarByDoctor(params: any = {}): Promise<any | null> {
    try {
      const response = await this.http.get(`v1/doctors/${params.doctorId}/schedule-daily`, {
        params: {
          week: params.week,
          week_type: params.weekType,
          doctorId: params.doctorId,
        },
      });
      return response.data;
    } catch (e) {
      console.log("ScheduleRepository.getCalendarByDoctor", e.response);
    }

    return null;
  }

  async toggleTime(timestamp: number): Promise<{ success: boolean; message: string }> {
    try {
      const response = await this.http.patch(`v1/mis/calendar-schedule`, { timestamp: timestamp });
      return { success: response.data.success, message: response.data.message };
    } catch (e) {
      console.log("ScheduleRepository.toggleTime", e.response);
      return { success: false, message: e.response.data.message };
    }
  }

  async toggleTimeByDoctor(timestamp: number, id: number): Promise<{ success: boolean; message: string }> {
    try {
      const response = await this.http.patch(`v1/doctors/${id}/schedule-daily`, { timestamp: timestamp });
      return { success: response.data.success, message: response.data.message };
    } catch (e) {
      console.log("ScheduleRepository.toggleTimeByDoctor", e.response);
      return { success: false, message: e.response.data.message };
    }
  }

  async getDoctors(): Promise<{ success: boolean; message: string; data: any[] | null }> {
    try {
      const response = await this.http.get(`v1/doctors/`);
      const doctors = response.data.data.map((el) => {
        if (el.user_first_name !== "Шлюз") {
          return {
            id: el.user_id,
            name: `${el.user_last_name} ${el.user_first_name} ${el.user_middle_name}`,
          };
        }
      });

      return { success: true, message: "ok", data: doctors };
    } catch (e) {
      console.log("ScheduleRepository.getDoctors", e.response);
      return { success: false, message: e.response.data.message, data: null };
    }
  }
}
